.singleTable thead {
  display: none;
}

.singleTable .euiTableRow.euiTableRow-isExpandable,
.singleTable .euiTableRow.euiTableRow-hasActions {
  background-image: linear-gradient(
    to right,
    rgba(152, 162, 179, 0.1) 0,
    rgba(152, 162, 179, 0.1) 1px,
    transparent 1px,
    transparent 100%
  );
  background-size: 40px 100%;
  background-position-x: right;
  background-repeat: no-repeat;
  padding-right: 40px;
  position: relative;
}

.singleTable .euiTableRow {
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  margin-bottom: 8px;
}

.singleTable .euiTableRow {
  box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
    0 1px 5px -2px rgba(152, 162, 179, 0.3);
  background-color: #fff;
  border: 1px solid #d3dae6;
  border-radius: 4px;
  flex-grow: 1;
}

.singleTable .euiTableRowCell {
  display: block;
  min-width: 50%;
  border: none;
}

.singleTable .euiTableHeaderMobile {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
}

.singleTable .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions {
  min-width: 0;
  width: 24px;
  position: absolute;
  top: 16px;
  right: 8px;
}

.singleTable
  .euiTableRow.euiTableRow-hasActions
  .euiTableRowCell--hasActions
  .euiTableCellContent {
  flex-direction: column;
  padding: 0;
}

.singleTable .euiTableCellContent--alignRight {
  justify-content: flex-start;
}

.singleTable .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
  margin-left: 0;
}

.singleTable
  .euiTableRow.euiTableRow-isExpandable
  .euiTableRowCell--isExpander::before,
.singleTable
  .euiTableRow.euiTableRow-hasActions
  .euiTableRowCell--hasActions::before {
  display: none;
}

.singleTable
  .euiTableRow-hasActions
  .euiTableCellContent--showOnHover
  .euiTableCellContent__hoverItem {
  opacity: 1;
  filter: none;
  margin-left: 0;
  margin-bottom: 8px;
}

.singleTable .euiTableRowCell__mobileHeader {
  max-width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  font-size: 11px;
  font-size: 0.6875rem;
  display: block !important;
  color: #69707d;
  padding: 8px;
  padding-bottom: 0;
  margin-bottom: -8px;
  min-height: 24px;
}
