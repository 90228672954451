.mapPanel {
  line-height: 1.2em;
  font-family: "Lato", sans-serif;
  background-color: #f5f8fa;
  padding: 14px 7px;
  width: 500px;
  top: 10px;
  left: 10px;
  //   min-height: 300px;
  max-height: calc(100% - 20px);
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid lightgrey;
  .content {
    position: relative;
    overflow: hidden;
  }
  .header {
    font-weight: 600;
    margin-top: 1em;
    padding-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-top: 1px solid#e3e6ef;
    border-bottom: 1px solid#e3e6ef;

    h3 {
      font-size: 1.2em;
      font-weight: 600;
    }
    .collections {
      margin-top: 2px;
    }
    .tag {
      background-color: #e3e6ef;
      padding: 2px 8px;
      display: inline-block;
      border-radius: 2px;
    }
  }
}
