@media only screen and (min-width: 768px) {
  .entriesMap {
    margin-left: 48px;
  }
}

@media only screen and (min-width: 1200px) {
  .euiNavDrawer-isLocked + .entriesMap {
    margin-left: 240px !important;
    transition: margin 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
  }
}
.entriesMap {
  height: calc(100vh - 49px);
  position: relative;
}
